import React from "react";
import "./index.css";
import { Link } from "react-router-dom";

const index = () => {
  return (
    <div class="aboutContainer">
      <div class="savings-header">
        <h3>Coaching Center</h3>
      </div>
      <div class="body">
        <h2>HOW TO INCREASE YOUR SAVINGS?</h2>
        <p>
          We are so happy to write on this important topic, that is so central
          to the financial wellbeing of many people. Today, we will be looking
          at How to Save Money. Undoubtedly, Americans are very hardworking but
          majority struggle to save money. To start with, what is Savings? To
          simply put this, Savings is Income minus Consumption. (Savings =
          Income – Consumption). Some schools of thought refer to it as deferred
          consumption. Two fundamental concepts here, Income – which is money
          earned or unearned, (say your paycheck or investment type income), and
          Consumption – your spending, things that you spend money on (example,
          debt payments, groceries, rent etc.) So, in other words, if you want
          to build up your savings, you either increase your income or reduce
          your spending. But in our society today, it is seldom for people to
          increase their income. For some class of people regardless of how many
          hours of work they put in, the effect is still the same. Accumulating
          savings is mostly attributed to hard work. This widely accepted
          concept is true to some extent, but we need to start putting more
          emphasis on the other element of this equation i.e., Consumption – how
          to reduce or defer consumption, for us to increase our savings? If you
          are wondering, how can I do this? Then you’re not alone. Even though
          the savings equation is universal, but everybody’s situation is
          different and requires a different approach. Before we proceed, let’s
          share with you some national data from the U.S Bureau of Labor
          Statistics on areas where average Americans spend their Income. In
          2021, overall spending increased in all major components by 9.1%
          compared to 2020 consisting of expenditures like, Transportation,
          Food, Housing, Apparel and Services, Entertainment, and Personal
          insurance and Pension. But let’s don’t bore you with all these
          numbers, because the problem of lack of savings is evident and all
          over. Now here are some areas to look at that might help you take care
          of this other half of the savings’ equation. It is not a magic bullet,
          but we believe if you prioritize these, with the universe being your
          helper, you might be able to increase your savings.
        </p>

        <ul>
          <li>
            Make a Resolution and Believe – we’ve all made new years’
            resolutions at some point in our lives, whether to lose weight or
            let go of toxic friends. Do we always meet those goals? Not always.
            The problem most times is we lack a strong belief system. The most
            important thing is to decide what you want to accomplish. When you
            have a resolution and a strong belief system, positive things begin
            to happen. What is belief? It is not how pious you are; it is the
            power of visualization and having the feeling that whatever you want
            is already at hand. The Universe has been perfected for us and
            whatever you want you can achieve if you work towards it and
            believe. So, whether you want to save money to pay off your debts,
            buy a house or go on vacation, work earnestly towards your goal, and
            begin to feel that sense of achievement. Write your resolution
            somewhere and be sure to read this daily. It’s all about energy,
            magnetize the inside then you will attract everything on the
            outside. Therefore, make a resolution to cut down on your expenses
            and start building your savings, and develop the habit of making
            daily positive affirmations about your goals while feeling and
            visualizing that your goal has already been achieved. Never doubt
            the power of the universe, just believe.
          </li>
          <br />
          <li>
            Determine where you stand financially – there is a saying that if
            you fail to plan, then you plan to fail. But planning does not only
            involve us trying to achieve a specific project like getting married
            or coming up with a marketing campaign. It is also central in
            helping us to save money and cut down on our consumption. Get a list
            of all your expenses whether biweekly or monthly and write them on a
            journal. Put expenses on a scale of preference that is categorize
            them according to priority with the most important being at the top
            and the least at the bottom. When you look at this list, you will
            notice that your expenses fall into two types; those that you need
            to survive and those that you don’t need to survive. The goal of
            this article is to help you reduce on your spending on things that
            you need to survive and delay or let go of the consumption of the
            things that you don’t need to survive.
          </li>
          <br />
          <li>
            Be aggressive to save money – we all know it is a hard thing to
            change certain habits especially when they bring you a level of
            excitement and comfort, but with belief and a firm conviction to get
            to your goal, you will make it. You should value every penny you
            spend out. After knowing where you stand financially, develop a
            consistent habit of saving money. Rather than getting a fixed
            percentage, for a start, determine a range for a start say for
            instance, every paycheck I am putting $100 to $500. Do this until
            you have developed a passion for saving money. This passion will
            grow once you start seeing how your savings balance is increasing
            because there is a sense of accomplishment that comes with saving
            money. Don’t be disappointed if you don’t make the top of your range
            but be sure to stick between this range and save some amount.
          </li>
          <br />
          <li>
            Find someone to hold you accountable – think of this like having a
            gym buddy. As with all our New Year resolutions, we express a great
            desire to lose weight by having a consistent schedule to exercise or
            eat healthy. But we most times fall short of this desire as our
            muscles get tired or our minds focus on other areas. However, if you
            have someone that holds you accountable or challenges you, then most
            times we make some positive efforts to meet our goals. This is where
            the Savings Box Mobile App comes in. This App will help you save
            money with known friends and family, and it also has a Shop to
            search for cheaper prices all from a one-stop shop. The App is
            available in both App Store and Google Play Store.
          </li>
          <br />
          <li>
            Build up an emergency fund before you start paying off debts. This
            gives you two things: Peace of mind, and something to look back at
            and say yes, I can do this.
          </li>
          <br />
          <li>
            Be yourself and do not compare yourself with others – when you are
            going through a change, the best thing is to be the best version of
            yourself. There might be instances when people will say you are
            miserly or stingy but don’t fret because your goal is at hand.
          </li>
          <br />
          <li>
            Be frugal but don’t be cheap – there is a thin line between being
            frugal and being cheap. To be frugal is to avoid excesses and
            wastages while getting value for your money. Whereas to be cheap is
            spending money with no regard for the value of your money. You can
            use the following to help you be frugal: -If you have debts, contact
            your lenders to see if they have any programs to help you reduce
            your debts payments -Look for discounts or promotions before making
            a purchase -Use the next pay day rule before you buy something. Make
            a list of things to buy before you receive your next paycheck.
            Again, separate these into things that you need and those that you
            want. Be sure to look at this list daily and reconsider your options
            by asking yourself; Are these items necessary or some of them can be
            delayed for a later date? By developing this habit, you will notice
            that when you get your paycheck some of these items on your list
            will no longer be a necessity and you can easily avoid making these
            impulse buying decisions.
          </li>
          <br />
          <li>
            Cut down on your Consumption/Expenses – You won’t be able to build
            up your savings if you don’t control how you spend money. Cutting
            down your expenses is all about adjusting your lifestyle. Focusing
            on those things that you need and delaying those that you want. The
            core areas that continue to account for increase in consumers
            expenditure are Housing, Transportation, Food, Entertainment, and
            Clothing. So, take a keen look at your personal budget and come up
            with a plan how you can cut down on some of your expenses on these
            areas. For example, on housing – you might want to move in with a
            Roommate or a Partner or a Family member. On Food – start cooking
            your own food instead of dining out. There are so many helpful
            videos on YouTube that can help you prepare your own food at home.
            Also, learn to manage your left-over food. On Entertainment – cut
            down on the number of times you entertain yourself or friends to
            maybe once a month. These are tough decisions, but they are worth
            taking to help you meet your savings goal.
          </li>
          <br />
          <li>
            Avoid taking on New Debts – if you already have debts you’re paying
            on, it is a good practice to focus on reducing your debts. If you
            can’t reduce them significantly, be sure not to take on new debts
            that will cause you to increase your monthly payments especially in
            times of rising interest rates – this includes avoiding Payday
            loans. Also, try your best to pay the minimum amount due for the
            time being to avoid delinquencies that will further ruin your credit
            score. We will look at some of the ways to help reduce your debts in
            another article.
          </li>
          <br />
          <li>
            Get a Side Hustle – It’s easy to lose motivation when you’re going
            through tough financial times. However, having a side hustle is
            great for your mental and physical health. You’ll feel more
            confident about your abilities when you have extra money coming in
            each month. There are many ways to start a side business, like dog
            walking or tutoring. If you’re looking for an idea of what projects
            need to be done on the Internet, do some research online before
            diving into something too big too soon that might not work out as
            planned. You should also make sure that you’ll have time for this
            project each week or month. If you need to quickly boost your mood,
            think of something small that you can do as a side business. For
            example, it’s easy to find freelance jobs on websites like
            Fiverr.com, and they pay well in most cases.
          </li>
        </ul>
      </div>

      <footer class="footer">
        <div className="footer-links">
          <Link to="/privacy">Privacy Policy</Link>
          <div class="vr"></div>
          <Link to="/terms">Terms of Use</Link>
          <div class="vr"></div>
          <Link to="/partnership">Partnership</Link>
        </div>
        <div class="containerp">
          <p>&copy; 2023 THE SAVINGSBOX INC.</p>
        </div>
      </footer>
    </div>
  );
};

export default index;
