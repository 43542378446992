// import React from "react";
import "./index.css";
// import { Link } from "react-router-dom";
// import apple from "../../Imgs/apple.png";
// import google from "../../Imgs/google.png";

// const index = () => {
//   return (
//     <div className="aboutContainer">
//       <div className="savings-header">
//         <h3>Frequently Asked Questions (FAQs) </h3>
//       </div>
//       <div className="body">
//         <h3>What is Savings Box?</h3>
//         <ul>
//           <li>
//             Savings Box is a mobile app designed to help you save money, manage
//             your finances, and meet your spending goals.
//           </li>
//         </ul>
//         <h3>How does Savings Box work?</h3>
//         <p>
//           Savings Box provides a secure platform for saving money, making
//           transfers, accessing financial coaching, and more. It uses technology
//           and AI to help you save and manage your finances efficiently.{" "}
//         </p>
//         <li>Create a group Savings Box in your account.</li>
//         <li>
//           Invite your friends and family in the Savings Box. They will receive a
//           notification and if they are interested and accept the invitation,
//           they will be added to the Savings Box
//         </li>
//         <li>
//           Add a contribution amount, and a frequency whether Bi-weekly or
//           Monthly.
//         </li>
//         <li>Select a start date. </li>
//         <li>Access more robust AI analysis and insights.</li>
//         <li>Access financial coaching from finance professionals.</li>
//         <li>
//           Gain access to cheaper and comparable prices from a wide range of our
//           partner businesses.
//         </li>
//         <li>No hidden fees.</li>

//         <p>
//           And once that is done, you are all set to embark on your savings
//           journey with ease and transparency. Every member will be able to
//           monitor the status of the Box on our Box Analytics platform and hold
//           each other accountable. You can also chat with members of your box,
//           all within the App using our end-to-end encrypted chat system. Also,
//           if you don’t want to be part of the group box, we have an option for
//           you too. Just create a Personal Box and make your contributions as The
//           Savings Box App help you meet your savings goals.
//         </p>

//         <h3>How will I get my money?</h3>
//         <p>
//           At the end of the Box period, members contributions will be
//           transferred to their wallet on the Savings Box App. Members can either
//           choose to transfer their contributions to their bank accounts or use
//           the balance in their wallets to shop through our Savings Box Shop,
//           where they can compare and benefit from affordable prices from our
//           Partner businesses. Worried about your money? You don’t have to, as
//           all our contributions are secured with our Partner FDIC bank.
//         </p>

//         <h3>Is my financial information safe with Savings Box?</h3>
//         <li>
//           Yes, we take security seriously. Savings Box uses bank-level
//           encryption to protect your information and ensures your data is
//           secure.
//         </li>

//         <h3>How can businesses list their products on the Savings Box</h3>
//         <li>
//           Businesses can list their products and advertise on our shop by
//           downloading the Savings Box Vendor App for free on both the App Store
//           and Google Play Store.
//         </li>

//         <h3>Can I access Savings Box on multiple devices?</h3>
//         <li>
//           Yes, Savings Box is available for both iOS and Android devices and
//           supports smartphones and tablets. It offers seamless synchronization
//           across your devices.
//         </li>

//         <h3>Are there any hidden fees with Savings Box?</h3>
//         <li>
//           No, we do not have hidden fees. We are committed to transparency and
//           providing a cost-effective solution to help you save.
//         </li>

//         <h3>How can I get in touch with customer support?</h3>
//         <li>
//           You can reach our customer support team by emailing
//           support@thesavingsbox.com. We also offer live chat support for quick
//           assistance.
//         </li>

//         <h3>Can I save money with friends or family using Savings Box?</h3>
//         <li>
//           You can reach our customer support team by emailing
//           support@thesavingsbox.com. We also offer live chat support for quick
//           assistance.
//         </li>

//         <h3>What kind of financial coaching is available on Savings Box?</h3>
//         <li>
//           ou can reach our customer support team by emailing
//           support@thesavingsbox.com. We also offer live chat support for quick
//           assistance.
//         </li>

//         <h3>Can I shop through Savings Box to access better prices?</h3>
//         <li>
//           You can reach our customer support team by emailing
//           support@thesavingsbox.com. We also offer live chat support for quick
//           assistance.
//         </li>

//         <h3>How do I download Savings Box?</h3>
//         <li>
//           You can join our community of users by downloading Savings Box for
//           free on both the App Store and Google Play Store. If you're a
//           business, you can also download Savings Box Vendor.
//         </li>

//         <h3> How can I stay connected with Savings Box updates and tips?</h3>
//         <li>
//           Follow us on social media for the latest updates, tips, and more!
//         </li>

//         <h3>Does Savings Box have a referral program?</h3>
//         <li>
//           Not yet, we will soon roll out our referral program that allows you to
//           earn rewards for referring friends and family to Savings Box.
//         </li>

//         <h3>Does Savings Box pay interest.</h3>
//         <li>
//           No. But all our contributions are secured with our Partner FDIC bank
//         </li>
//         <h3>Is Savings Box available internationally?</h3>
//         <li>
//           Currently, Savings Box is available in the U.S. We plan to expand our
//           availability to more regions in the future.
//         </li>
//       </div>

//       <footer className="footer">
//         <div className="footer-links">
//           <Link to="/privacy">Privacy Policy</Link>
//           <div className="vr"></div>
//           <Link to="/terms">Terms of Use</Link>
//           <div className="vr"></div>
//           <Link to="/partnership">Partnership</Link>
//         </div>
//         <div class="containerp">
//           <p>&copy; 2023 THE SAVINGSBOX INC.</p>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default index;

// import React from "react";
import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return id === open ? (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12L18 12"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12H18M12 6V18"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const Index = () => {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <div className="aboutContainer" style={{ width: "100vw" }}>
      <div className="savings-header">
        <h3>Frequently Asked Questions (FAQs) </h3>
      </div>
      <div className="body">
        <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(1)}
            className={`${
              open === 1 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            What is Savings Box?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            Savings Box is a mobile app designed to help you save money, manage
            your finances, and meet your spending goals.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(2)}
            className={`${
              open === 2 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            How does Savings Box work?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            Savings Box provides a secure platform for saving money, making transfers, accessing financial coaching, and more. It uses technology and AI to help you save and manage your finances efficiently.
            <ul>
              <li>• Create a group Savings Box in your account.</li>
              <li>• Invite your friends and family in the Savings Box. They will receive a notification and if they are interested and accept the invitation, they will be added to the Savings Box</li>
              <li>• Add a contribution amount, and a frequency whether Bi-weekly or Monthly.</li>
              <li>• Select a start date.</li>
              <li>• Choose how many months you want the Box to run for.</li>
              <li>• Select your spending goal.</li>
              <li>• Choose your payment method either automatically or manually. You can use bank transfer, debit card (Visa or Master card) to add money to your box.</li>
            </ul>
            Once that is done, you are all set to embark on your savings journey with ease and transparency. Every member will be able to monitor the status of the Box on our Box Analytics platform and hold each other accountable.
            You can also chat with members of your box, all within the App using our end-to-end encrypted chat system.
            Also, if you don’t want to be part of the group box, we have an option for you too. Just create a Personal Box and make your contributions as The Savings Box App help you meet your savings goals.
          </AccordionBody>
        </Accordion>

        <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(3)}
            className={`${
              open === 3 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            How will I get my money?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            At the end of the Box period, members contributions will be
            transferred to their wallet on the Savings Box App. Members can
            either choose to transfer their contributions to their bank accounts
            or use the balance in their wallets to shop through our Savings Box
            Shop, where they can compare and benefit from affordable prices from
            our Partner businesses.
            
             Worried about your money? You don’t have to,
            as all our contributions are secured with our Partner FDIC bank.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(4)}
            className={`${
              open === 4 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            Is my financial information safe with Savings Box?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            Yes, we take security seriously. Savings Box uses bank-level
            encryption to protect your information and ensures your data is
            secure.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(5)}
            className={`${
              open === 5 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            How can businesses list their products on the Savings Box?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            Businesses can list their products and advertise on our shop by
            downloading the Savings Box Vendor App for free on both the App
            Store and Google Play Store.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(6)}
            className={`${
              open === 6 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            Can I access Savings Box on multiple devices?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            Yes, Savings Box is available for both iOS and Android devices and
            supports smartphones and tablets. It offers seamless synchronization
            across your devices.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 7} icon={<Icon id={7} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(7)}
            className={`${
              open === 7 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            Are there any hidden fees with Savings Box?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            No, we do not have hidden fees. We are committed to transparency and
            providing a cost-effective solution to help you save.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 8} icon={<Icon id={8} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(8)}
            className={`${
              open === 8 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            How can I get in touch with customer support?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            You can reach our customer support team by emailing
            support@thesavingsbox.com. We also offer live chat support for quick
            assistance.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 9} icon={<Icon id={9} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(9)}
            className={`${
              open === 9 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            Can I save money with friends or family using Savings Box?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            Yes, Savings Box allows you to save money with trusted friends and
            family members, making saving effortless and collaborative.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 10} icon={<Icon id={10} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(10)}
            className={`${
              open === 10 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            What kind of financial coaching is available on Savings Box?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            Savings Box connects you with finance professionals who can provide
            insights and guidance to help you achieve your financial goals. The
            app also provides AI driven analytics to provide robust insights to
            help you save money and manage debt.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 11} icon={<Icon id={11} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(11)}
            className={`${
              open === 11 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            Can I shop through Savings Box to access better prices?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            Yes, our Savings Box shop uses advanced algorithms and machine
            learning to help you find competitive prices and save on your
            purchases.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 12} icon={<Icon id={12} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(12)}
            className={`${
              open === 12 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            How do I download Savings Box?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            You can join our community of users by downloading Savings Box for
            free on both the App Store and Google Play Store. If you're a
            business, you can also download Savings Box Vendor.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 13} icon={<Icon id={13} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(13)}
            className={`${
              open === 13 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            How can I stay connected with Savings Box updates and tips?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            Follow us on social media for the latest updates, tips, and more!
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 14} icon={<Icon id={14} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(14)}
            className={`${
              open === 14 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            Does Savings Box have a referral program?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            Not yet, we will soon roll out our referral program that allows you
            to earn rewards for referring friends and family to Savings Box.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 15} icon={<Icon id={15} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(15)}
            className={`${
              open === 15 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            Does Savings Box pay interest.
          </AccordionHeader>
          <AccordionBody className="border p-2">
            No. But all our contributions are secured with our Partner FDIC
            bank.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 16} icon={<Icon id={16} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(16)}
            className={`${
              open === 16 ? "bg-gray-300 border-none rounded-md" : ""
            }  rounded-md px-2 my-2 border`}
          >
            Is Savings Box available internationally?
          </AccordionHeader>
          <AccordionBody className="border p-2">
            Currently, Savings Box is available in the U.S. We plan to expand
            our availability to more regions in the future.
          </AccordionBody>
        </Accordion>
      </div>
    </div>
  );
};

export default Index;
